import React from "react";
import './index.css';
export class LandingPage extends React.Component {
  render() {
    return (
      <div id="watchone">
        <h1 class='whatson'>Whats on Phils Screen</h1>
      </div>
    );
  }
}